<!-- (c) 智汇考题 -->
<template>
    <div class="container">
        <nav-header :title="header_title"></nav-header>
        <div>
            <div class="info">
                <div>试卷名称：<span class="ml-5">{{detail.title}}</span></div>
                <div>考试时间：<span class="ml-5">{{detail.expiredMinutes}}</span> 分钟</div>
                <div>总分：<span class="ml-5">{{detail.score}} </span>分</div>
                <div>及格：<span class="ml-5">{{detail.passScore}} </span>分</div>
                <span>本套试卷共<span class="ml-5">{{detail.questionCount}}</span> 道题，分<span class="ml-5">{{questions.length}}</span> 个题型</span>
                <div v-for="item in questions" :key="item.type">
                    <span v-if="item.type == 1">单选题 共<span class="ml-5">{{item.count}}</span> 道题</span>
                    <span v-else-if="item.type == 2">多选题 共<span class="ml-5">{{item.count}}</span> 道题</span>
                    <span v-else-if="item.type == 3">填空题 共<span class="ml-5">{{item.count}}</span> 道题</span>
                    <span v-else-if="item.type == 4">简答题 共<span class="ml-5">{{item.count}}</span> 道题</span>
                    <span v-else-if="item.type == 5">判断题 共<span class="ml-5">{{item.count}}</span> 道题</span>
                    <span v-else-if="item.type == 6">题帽题 共<span class="ml-5">{{item.count}}</span> 道题</span>
                </div>
            </div>
            <div class="footer-box borderbox" v-if="can_join && detail.status === 1 && detail.questionCount">
                <div class="button" @click="join()"> 开始考试 </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import NavHeader from "../../../components/nav-header.vue";

export default {
    components: {
        NavHeader
    },
    data() {
        return {
            id: this.$route.query.id,
            header_title: '考试详情',
            loading: false,
            detail: '',
            questions: [],
            surplus: 99999,
            can_join: false,
            joinLoading: false,
        }
    },
    mounted() {
        this.getData();
    },
    computed: {
        ...mapState(["isLogin"]),
    },
    methods: {
        getData() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.$api.Exam.MockPaper.Detail(this.id).then((res) => {
                this.loading = false;
                this.detail = res.data.paper;
                this.questions = res.data.questions;
                this.can_join = res.data.paper.userCanJoin;
                this.join_count = res.data.examMockPaperRecords.length;
            });
        },
        join(userPaper) {
            if (!this.isLogin) {
              this.$router.push({
                name: "Login",
                query: {
                url: window.location.href,
                },
              });
              return;
            }
            if (this.joinLoading) {
                return;
            }
            if (this.can_join === false) {
                this.$message.error("无权限参与");
                return;
            }
            if (typeof userPaper !== "undefined") {
                if (userPaper.status === 3) {
                    this.$message.error("请等待阅卷完成查看");
                    return;
                }
                this.$router.push({
                    name: "AnswerExamsPlay",
                    query: {
                        id: this.id,
                        pid: userPaper.id,
                    },
                });
                return;
            }
            this.joinLoading = true;
            this.$api.Exam.MockPaper.Join(this.id)
                .then((res) => {
                    this.joinLoading = false;
                    let record_id = res.data.recordId;
                    this.$router.push({
                        name: "AnswerMocksPlay",
                        query: {
                            id: this.id,
                            pid: record_id,
                        },
                    });
                })
                .catch((e) => {
                    this.joinLoading = false;
                    this.$message.error(e.message);
            });
        },
    }
}
</script>
<style lang="less" scoped>
    .container {
        background: #f9f9f9;
    }   
    .justify-center{
        justify-content: center;
    }
    .flex-row  {
        flex-direction: row;
    }
    .ml-5{
        color: #3ca7fa;
    }
    .margin-top{
        margin-top: 10px;
    }
    .info {
        background: #fff;
        margin: 80px 20px 90px 20px;
        line-height: 40px;
        padding: 15px 0px 15px 20px;
        border-radius: 10px;
        .pass-score{
            height: 16px;
            font-size: 16px;
            font-weight: 500;
            color: #333;
            line-height: 16px;
        }
        .try-times{
            margin-left: 20px;
        }
    }
    .sum {
        width: 100%;
        height: 13px;
        margin-top: 30px;
        span {
            height: 13px;
            font-size: 13px;
            font-weight: 400;
            color: #666;
            line-height: 13px;
        }
    }
    .questions {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 15px 100px 15px;
        .item-question {
            width: 100%;
            height: 83px;
            background: #f1f2f6;
            border-radius: 8px;
            margin-top: 20px;
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: center;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            .name {
                width: 100%;
                height: 24px;
                font-size: 16px;
                font-weight: 500;
                color: #333;
                line-height: 24px;
                margin-bottom: 15px;
            }
            .num {
                font-size: 14px;
            }
        }
    }
    .flex-col {
        display: flex;
        flex-direction: column;
    }
    .footer-box{
        position: fixed;
        bottom: 0;
        width: 100%;
        float: left;
        height: auto;
        background: #fff;
        z-index: 100;
        border-top: 1px solid #e5e5e5;
        .button {
            margin: 10px 15px;
            width: auto;
            height: 50px;
            background: linear-gradient(315deg,#33baf7,#1784ed);
            box-shadow: 0 4px 8px 0 hsla(0,0%,40%,.1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
        }
    }
</style>